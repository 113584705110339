"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import ErrorPage from "../../pages/500";

export default function ErrorPages({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);

  return <ErrorPage />;
}
